import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import logo from '../images/logo@2x.png';
import logoInverted from '../images/logo-inverted@2x.png';
import twitterWhite from '../images/twitter-white@2x.png';
import linkedinWhite from '../images/linkedin-white@2x.png';
import navJson from '../json/nav.json';
import Nav from './Nav';
import './Layout.scss';

export const LocaleContext = React.createContext();
const DEFAULT_LOCALE = 'pl';

const Layout = ({ children, locale = DEFAULT_LOCALE, localizedPages = {} }) => {
  const metadata = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          linkedInUrl
          twitterUrl
          description
          title
        }
      }
    }
  `);

  const menuItems = navJson[locale];

  const getLocalizedPageUrl = (forLocale) => {
    if (forLocale === DEFAULT_LOCALE) {
      return localizedPages[DEFAULT_LOCALE] || '/';
    }

    return `/${forLocale}${localizedPages[forLocale] || '/'}`;
  };

  return (
    <LocaleContext.Provider value={{ locale, getLocalizedPageUrl }}>
      <header className="header">
        <Link
          to={menuItems[0].href}
          title="Strona Główna"
          className="header__logo-link"
        >
          <img src={logo} alt="REFORM" className="header__logo" />
        </Link>
        <Nav menuItems={menuItems} metadata={metadata} />
      </header>
      <main>{children}</main>
      <footer className="footer">
        <div className="footer__content-wrapper">
          <Link
            to={menuItems[0].href}
            className="footer__logo-link"
            title="Strona Główna"
          >
            <img src={logoInverted} alt="REFORM" className="footer__logo" />
          </Link>
          <span className="footer__copyright">
            &copy; {new Date().getFullYear()} Fundacja Instytut Reform. All
            Rights Reserved
          </span>
          <div className="footer__social">
            <a
              href={metadata.site.siteMetadata.twitterUrl}
              title="Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterWhite} alt="Twitter" />
            </a>
            <a
              href={metadata.site.siteMetadata.linkedInUrl}
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinWhite} alt="LinkedIn" />
            </a>
          </div>
          <span className="footer__address">
            Fundacja Instytut Reform
            <br />
            ul. Puławska 26/1, 02-512 Warszawa
            <br />
            NIP: 5213951414
            <br />
            KRS: 0000941460
          </span>
          <a
            href="mailto:office@ireform.eu"
            title="E-mail"
            className="footer__email"
          >
            office@ireform.eu
          </a>
        </div>
      </footer>
    </LocaleContext.Provider>
  );
};

export default Layout;
