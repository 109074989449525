import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const _locale = { title: { pl: 'Instytut Reform', en: 'Reform Institute' } };

const Seo = ({ title, description, locale = 'pl' }) => {
  const {
    site: { siteMetadata: data },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          linkedInUrl
          twitterUrl
          description
          title
          siteUrl
          twitterUsername
        }
      }
    }
  `);

  const _title = `${title ? `${title} | ` : ''}${_locale.title[locale]}`;
  const _description = description || data.description;

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      <title>{_title}</title>
      <meta name="description" content={_description} />
      <meta property="og:image" content="https://ireform.eu/og-image.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={_title} />
      <meta property="og:description" content={_description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={data.twitterUsername} />
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={_description} />
      <link rel="icon" type="image/png" href="/favicon.png" />
    </Helmet>
  );
};

export default Seo;
