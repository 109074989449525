import React from 'react';
import { Link } from 'gatsby';
import linkedin from '../images/linkedin-black@2x.png';
import twitter from '../images/twitter-black@2x.png';
import { LocaleContext } from './Layout';
import MobileNav from './MobileNav';
import './Nav.scss';

const Nav = ({ menuItems, metadata }) => {
  const { locale, getLocalizedPageUrl } = React.useContext(LocaleContext);

  return (
    <nav className="nav__menu">
      <div className="nav__menu-items">
        {menuItems.map((item, index) => {
          if (item.children) {
            return (
              <div className="nav__dropdown" key={item.title}>
                <Link
                  to={item.href}
                  activeClassName="is-active"
                  className="nav__menu-item"
                  onClick={(e) => e.preventDefault()}
                  partiallyActive={true}
                >
                  {item.title}
                </Link>
                <div className="nav__dropdown-content">
                  {item.children.map((childItem) => (
                    <Link
                      to={childItem.href}
                      className="nav__menu-item"
                      activeClassName="is-active"
                      partiallyActive={true}
                      key={childItem.title}
                    >
                      {childItem.title}
                    </Link>
                  ))}
                </div>
              </div>
            );
          }

          return (
            <Link
              to={item.href}
              className="nav__menu-item"
              activeClassName="is-active"
              partiallyActive={index !== 0}
              key={item.title}
            >
              {item.title}
            </Link>
          );
        })}
      </div>
      <div className="nav__social">
        <a
          href={metadata.site.siteMetadata.twitterUrl}
          title="Twitter"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitter} alt="Twitter" className="nav__social-icon" />
        </a>
        <a
          href={metadata.site.siteMetadata.linkedInUrl}
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="LinkedIn" className="nav__social-icon" />
        </a>
      </div>
      <div className="nav__lang">
        <Link
          to={getLocalizedPageUrl('pl')}
          className={locale === 'pl' ? 'is-active' : ''}
          title="Polski"
        >
          PL
        </Link>{' '}
        |{' '}
        <Link
          to={getLocalizedPageUrl('en')}
          className={locale === 'en' ? 'is-active' : ''}
          title="English"
        >
          EN
        </Link>
      </div>
      <MobileNav data={menuItems} />
    </nav>
  );
};

export default Nav;
