import React from 'react';
import './AccordionDropdown.scss';

const AccordionDropdown = ({ trigger, children, startOpen = false }) => {
  const contentRef = React.useRef();
  const [contentHeight, setContentHeight] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(startOpen);
  const height = isOpen ? contentHeight : '0';

  const handleTriggerClick = (e) => {
    e.preventDefault();
    setIsOpen((isOpen) => !isOpen);
  };

  React.useEffect(() => {
    setContentHeight(contentRef.current.scrollHeight);
  }, []);

  const triggerProps = {
    onClick: handleTriggerClick,
  };

  return (
    <div>
      {trigger(triggerProps, isOpen)}
      <div className="accordion__content" style={{ height }} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};

export default AccordionDropdown;
