import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from '@reach/router';
import mobileNavImg from '../images/mobile-nav.png';
import twitterWhite from '../images/twitter-white@2x.png';
import linkedinWhite from '../images/linkedin-white@2x.png';
import logoInverted from '../images/logo-inverted.png';
import xImg from '../images/x.svg';
import { LocaleContext } from './Layout';
import AccordionDropdown from './AccordionDropdown';
import './MobileNav.scss';

const LanguageSelect = () => {
  const { locale, getLocalizedPageUrl } = React.useContext(LocaleContext);

  return (
    <>
      <Link
        to={getLocalizedPageUrl('pl')}
        className={locale === 'pl' ? 'is-active' : ''}
      >
        Polski
      </Link>{' '}
      |{' '}
      <Link
        to={getLocalizedPageUrl('en')}
        className={locale === 'en' ? 'is-active' : ''}
      >
        English
      </Link>
    </>
  );
};

const MobileNav = ({ data }) => {
  const metadata = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          linkedInUrl
          twitterUrl
          description
          title
        }
      }
    }
  `);

  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const location = useLocation();

  return (
    <>
      <button
        type="button"
        className="mobile-nav__trigger"
        onClick={handleOpen}
      >
        <img src={mobileNavImg} alt="Open menu" />
      </button>
      {isOpen && (
        <div className="mobile-nav__backdrop" onClick={handleClose}></div>
      )}
      <CSSTransition
        classNames="mobile-nav"
        timeout={300}
        in={isOpen}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <nav className="mobile-nav">
          <div className="mobile-nav__header">
            <img
              src={logoInverted}
              alt="Open menu"
              className="mobile-nav__logo"
            />
            <div className="mobile-nav__lang">
              <LanguageSelect />
            </div>
            <button className="mobile-nav__close" onClick={handleClose}>
              <img src={xImg} alt="Close" />
            </button>
          </div>
          <div className="mobile-nav__lang">
            <LanguageSelect />
          </div>
          <div className="mobile-nav__menu">
            <hr />
            {data.map((item, index) => {
              if (item.children) {
                return (
                  <AccordionDropdown
                    key={item.title}
                    startOpen={item.children.some((childItem) =>
                      location.pathname.startsWith(childItem.href)
                    )}
                    trigger={(props, isOpen) => (
                      <Link
                        to={item.href}
                        className="mobile-nav__menu-item"
                        partiallyActive={true}
                        activeClassName="is-active"
                        {...props}
                      >
                        {item.title}{' '}
                        <span
                          className="mobile-nav__submenu-arrow"
                          style={{
                            transform: `rotate(${isOpen ? -90 : 90}deg)`,
                          }}
                        >
                          &rarr;
                        </span>
                      </Link>
                    )}
                  >
                    <div className="mobile-nav__submenu">
                      {item.children.map((childItem) => (
                        <Link
                          to={childItem.href}
                          className="mobile-nav__menu-item"
                          activeClassName="is-active"
                          partiallyActive={true}
                          key={childItem.title}
                        >
                          {childItem.title}
                        </Link>
                      ))}
                    </div>
                  </AccordionDropdown>
                );
              }

              return (
                <Link
                  to={item.href}
                  className="mobile-nav__menu-item"
                  activeClassName="is-active"
                  partiallyActive={index !== 0}
                  key={item.title}
                >
                  {item.title}
                </Link>
              );
            })}
            <hr />
          </div>
          <div className="mobile-nav__social">
            <a
              href={metadata.site.siteMetadata.twitterUrl}
              title="Twitter"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterWhite} alt="Twitter" />
            </a>
            <a
              href={metadata.site.siteMetadata.linkedInUrl}
              title="LinkedIn"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedinWhite} alt="LinkedIn" />
            </a>
          </div>
        </nav>
      </CSSTransition>
    </>
  );
};

export default MobileNav;
